// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-freeware-index-js": () => import("./../src/pages/freeware-index.js" /* webpackChunkName: "component---src-pages-freeware-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-js": () => import("./../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects-index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-software-index-js": () => import("./../src/pages/software-index.js" /* webpackChunkName: "component---src-pages-software-index-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

