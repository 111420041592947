module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"M.Paccione Design & Development","short_name":"M.Paccione Design & Development","start_url":"/","background_color":"#555db0","theme_color":"#555db0","display":"standalone","icon":"src/assets/img/m-logo.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"49e8e3d47544fb8975be04e96b906f96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
